<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>{{ tituloPagina }}</titulo-pagina>
        </v-col>

        <v-col cols="12" v-if="!permissaoPagina">
          <bloco-erro>Acesso Negado</bloco-erro>
        </v-col>

        <v-col cols="12" v-if="permissaoPagina && !representanteEncontrado">
          <bloco-erro>Representante não Encontrado</bloco-erro>
        </v-col>

        <v-col cols="12" v-if="permissaoPagina && representanteEncontrado">
          <v-card>
            <v-card-text>
              <v-tabs v-model="tabs" color="orange darken-5">
                <v-tabs-slider color="orange"></v-tabs-slider>
                <v-tab color="orange"> Dados do Representante</v-tab>
                <v-tab color="orange"> Dados Bancários e Comissões</v-tab>

                <v-tabs-items v-model="tabs">
                  <v-tab-item :key="0">
                    <v-row class="mt-3 px-5">
                      <v-col cols="12" >
                        <v-text-field
                            dense
                            v-model.trim="formulario.nome_razao"
                            label="Nome/Razão Social"
                            :error-messages="erros.nome_razao"
                            prepend-inner-icon="mdi-account-tie"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.nome_razao && iconeErro) || ''"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.cpf_cnpj"
                            label="CPF/CNPJ"
                            :error-messages="erros.cpf_cnpj"
                            prepend-inner-icon="mdi-card-text-outline"
                            outlined
                            :append-icon="(!!erros.nome_razao && iconeErro) || ''"
                            v-mask="['###.###.###-##', '##.###.###/####-##']"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.email"
                            label="Email"
                            :error-messages="erros.email"
                            prepend-inner-icon="mdi-email"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.email && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                            dense
                            v-model="formulario.status_id"
                            label="Status do Representante"
                            :error-messages="erros.status_id"
                            :items="itensSelect.habilitacao"
                            item-text="label"
                            item-value="valor"
                            prepend-inner-icon="mdi-list-status"
                            outlined
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.cep"
                            label="CEP da Empresa"
                            :error-messages="erros.cep"
                            prepend-inner-icon="mdi-map-marker"
                            outlined
                            :append-icon="(!!erros.cep && iconeErro) || ''"
                            v-mask="'#####-###'"
                            @keyup="buscarCep()"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.logradouro"
                            label="Logradouro"
                            :error-messages="erros.logradouro"
                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.logradouro && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.logradouro_numero"
                            label="Numero"
                            :error-messages="erros.logradouro_numero"
                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.logradouro_numero && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.complemento"
                            label="Complemento"
                            :error-messages="erros.complemento"
                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.complemento && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.bairro"
                            label="Bairro"
                            :error-messages="erros.bairro"
                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.bairro && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.cidade"
                            label="Cidade"
                            :error-messages="erros.cidade"
                            prepend-inner-icon="mdi-map"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.cidade && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                            dense
                            v-model="formulario.uf"
                            label="UF"
                            :error-messages="erros.uf"
                            :items="itensSelect.ufs"
                            item-text="label"
                            item-value="id"
                            prepend-inner-icon="mdi-map"
                            outlined
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.ddd"
                            label="DDD"
                            :error-messages="erros.ddd"
                            prepend-inner-icon="mdi-phone"
                            outlined
                            :append-icon="(!!erros.ddd && iconeErro) || ''"
                            v-mask="'##'"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.telefone"
                            label="Telefone"
                            :error-messages="erros.telefone"
                            prepend-inner-icon="mdi-phone"
                            outlined
                            :append-icon="(!!erros.telefone && iconeErro) || ''"
                            v-mask="['###-####', '####-####','#####-####']"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="ma-5 float-right">
                      <v-btn
                          dark
                          color="orange"
                          @click="tabs = 1"
                      >Próximo passo
                        <v-icon>mdi-arrow-right-bold</v-icon>
                      </v-btn>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item :key="1">
                    <v-row class="mt-3 px-5">
                      <v-col cols="12" md="6">
                        <AutocompleteBancos
                            :id="formulario.banco"
                            v-on:bancoSelecionado="bancoRetornado"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <AutocompleteTipoConta
                            :id="formulario.tipo_conta"
                            v-on:tipoContaSelecionado="tipoContaRetornado"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.agencia"
                            label="Agencia"
                            :error-messages="erros.agencia"
                            background-color="white"
                            prepend-inner-icon="mdi-card-text-outline"
                            outlined
                            :append-icon="(!!erros.agencia && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.conta"
                            label="Conta"
                            :error-messages="erros.conta"
                            prepend-inner-icon="mdi-card-text-outline"
                            outlined
                            :append-icon="(!!erros.conta && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.valor_comissao_fixa"
                            label="Valor Comissão"
                            :error-messages="erros.conta"
                            outlined
                            type="number"
                            prepend-inner-icon="mdi-cash mdi-rotate-315"
                            :append-icon="(!!erros.valor_comissao_fixa && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.valor_comissao_porcento"
                            label="Porcentagem por Contrato"
                            :error-messages="erros.valor_comissao_porcento"
                            outlined
                            type="number"
                            prepend-inner-icon="mdi-percent"
                            :append-icon="(!!erros.valor_comissao_porcento && iconeErro) || ''"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" align="right">
                        <div class="mt-5">
                          <v-btn
                              :block="blockBtns"
                              dark
                              :class="{'mr-4': !blockBtns,'mb-4': blockBtns}"
                              depressed
                              color="orange"
                              @click="confirmarSaida()"
                          >
                            Ir para Listagem
                            <v-icon right>
                              mdi-table
                            </v-icon>
                          </v-btn>

                          <v-btn
                              :block="blockBtns"
                              dark
                              depressed

                              @click="submit()"
                          >
                            {{ tipoSubmit === "cadastrar" ? "Registrar" : "Editar" }}
                            <v-icon right>
                              mdi-send-check
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>

                  </v-tab-item>

                </v-tabs-items>
              </v-tabs>
            </v-card-text>
          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </menu-principal>
</template>

<script>
import MenuPrincipal from "../../components/MenuPrincipal";
import TituloPagina from "../../components/TituloPagina";
import BlocoErro from "../../components/BlocoErro";
import mixinGeral from "../../mixin/geral";
import empresasServices from "../../services/empresas";
import helpers from "@/assets/js/helpers";
import viaCepServices from "../../services/viaCep";
import AutocompleteBancos from "@/components/autocomplete/Bancos";
import AutocompleteTipoConta from "@/components/autocomplete/TipoContaBanco";
import representantes from "@/services/representantes";
import {mask} from "vue-the-mask";

export default {
  name: "Formulario",
  mixins: [mixinGeral],
  directives: {mask},
  components: {
    MenuPrincipal,
    TituloPagina,
    BlocoErro,
    AutocompleteBancos,
    AutocompleteTipoConta
  },
  data() {
    return {
      tabs: null,
      representanteEncontrado: true,
      representante_id: null,
      formulario: {
        nome_razao: null,
        cpf_cnpj: null,
        status_id: null,
        cep: null,
        logradouro: null,
        email: null,
        logradouro_numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        uf: null,
        ddd: null,
        telefone: null,
        valor_comissao_fixa: null,
        valor_comissao_porcento: null,
      },
      erros: {
        nome_razao: null,
        cpf_cnpj: null,
        status_id: null,
        cep: null,
        email: null,
        logradouro: null,
        logradouro_numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        uf: null,
        ddd: null,
        telefone: null,
        valor_comissao_fixa: null,
        valor_comissao_porcento: null,
      },
    };
  },
  computed: {

    iconeErro() {
      return "mdi-alert-octagram";
    },
    permissaoPagina() {
      return this.permissaoMaster;
    },
    tipoSubmit() {
      return this.$route.meta.submit;
    },
    tituloPagina() {
      if (this.tipoSubmit === "cadastrar") {
        return "Cadastrar Representante";
      }
      return "Editar Representante";
    },
    blockBtns() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    habilitacaoSubmit() {
      return !this.loading && !this.notificacao.status;
    },
    itensSelect() {
      const ufs = helpers.obterUfs();
      return {
        ufs,
        habilitacao: [
          {
            valor: false,
            label: "Desabilitada",
          },
          {
            valor: true,
            label: "Habilitada",
          },
        ],
      };
    },
  },
  methods: {
    bancoRetornado(value) {
      this.formulario.banco = value
    },
    tipoContaRetornado(value) {
      this.formulario.tipo_conta = value
    },
    async buscarRepresentante() {
      const id = this.$route.params.id;
      if (!id) {
        this.representanteEncontrado = false;
        return
      }

      await representantes.buscar(id).then(resp => {
        this.representante_id = resp.data.id;

        this.formulario.representante_id = resp.data.representante_id;
        this.formulario.nome_razao = resp.data.nome_razao;
        this.formulario.cpf_cnpj = resp.data.cpf_cnpj;
        this.formulario.status_id = resp.data.status_id;
        this.formulario.cep = resp.data.cep;
        this.formulario.email = resp.data.email;
        this.formulario.logradouro = resp.data.logradouro;
        this.formulario.logradouro_numero = resp.data.logradouro_numero;
        this.formulario.complemento = resp.data.complemento;
        this.formulario.bairro = resp.data.bairro;
        this.formulario.cidade = resp.data.cidade;
        this.formulario.uf = resp.data.uf;
        this.formulario.ddd = resp.data.ddd;
        this.formulario.telefone = resp.data.telefone;
        this.formulario.valor_comissao_fixa = resp.data.valor_comissao_fixa;
        this.formulario.valor_comissao_porcento = resp.data.valor_comissao_porcento;
        this.formulario.banco = resp.data.banco;
        this.formulario.tipo_conta = resp.data.tipo_conta;
        this.formulario.agencia = resp.data.agencia;
        this.formulario.conta = resp.data.conta;
      });


    },
    confirmarSaida() {
      this.$confirm("Deseja ser direcionado para lista de Representantes ?").then((res) => {
        if (res) {
          this.navegar("representantesIndex");
        }
      })
    },
    submit() {
      if (this.tipoSubmit === "cadastrar") return this.cadastrar();
      return this.atualizar();
    },
    async cadastrar() {
      this.erros = {};
      this.ativarLoading();
      try {
        await representantes.cadastrar(this.formulario);
        this.notificacaoSucesso("Cadastrado com Sucesso");
        this.navegar("representantesIndex");
      } catch (e) {
        this.interpretarErro(e);
        if (this.obterCodigoRequisicao(e) === 422) {
          this.apresentarErros(e.response.data.erros);
        }
      } finally {
        this.desativarLoading();
      }
    },
    async atualizar() {
      this.erros = {};
      this.ativarLoading();
      try {
        await representantes.editar(this.representante_id, this.formulario);
        this.notificacaoSucesso("Representante Editado com Sucesso");
        this.navegar("representantesIndex");
      } catch (e) {
        this.interpretarErro(e);
        if (this.obterCodigoRequisicao(e) === 422) {
          this.apresentarErros(e.response.data.erros);
        }
      } finally {
        this.desativarLoading();
      }
    },
    apresentarErros(erros) {
      if (!helpers.empty(erros.razao_social)) this.erros.nome_razao = erros.razao_social[0];
      if (!helpers.empty(erros.cpf_cnpj)) this.erros.cpf_cnpj = erros.cpf_cnpj[0];
      if (!helpers.empty(erros.status_id)) this.erros.status_id = erros.status_id[0];
      if (!helpers.empty(erros.cep)) this.erros.cep = erros.cep[0];
      if (!helpers.empty(erros.logradouro)) this.erros.logradouro = erros.logradouro[0];
      if (!helpers.empty(erros.logradouro_numero)) this.erros.logradouro_numero = erros.logradouro_numero[0];
      if (!helpers.empty(erros.complemento)) this.erros.complemento = erros.complemento[0];
      if (!helpers.empty(erros.bairro)) this.erros.bairro = erros.bairro[0];
      if (!helpers.empty(erros.cidade)) this.erros.cidade = erros.cidade[0];
      if (!helpers.empty(erros.uf)) this.erros.uf = erros.uf[0];
      if (!helpers.empty(erros.email)) this.erros.email = erros.email[0];
      if (!helpers.empty(erros.ddd)) this.erros.ddd = erros.ddd[0];
      if (!helpers.empty(erros.telefone)) this.erros.telefone = erros.telefone[0];
      if (!helpers.empty(erros.valor_comissao_taxa)) this.erros.valor_comissao_taxa = erros.valor_comissao_taxa[0];
      if (!helpers.empty(erros.valor_comissao_porcento)) this.erros.valor_comissao_porcento = erros.valor_comissao_porcento[0];
    },
    async buscarCep() {
      const regexCep = /^\d{1,5}-\d{3}$/;
      if (!regexCep.test(this.formulario.cep)) return;

      this.ativarLoading();
      try {
        let response = await viaCepServices.buscar(this.formulario.cep);
        let status = helpers.isset(() => response.status) ? response.status : false;

        if (status === 200 && !response.data.erro) {
          this.formulario.uf = response.data.uf;
          this.formulario.logradouro = response.data.logradouro;
          this.formulario.logradouro_numero = response.data.logradouro_numero;
          this.formulario.complemento = response.data.complemento;
          this.formulario.bairro = response.data.bairro;
          this.formulario.cidade = response.data.localidade;
        } else {
          this.setNotificacao({
            status: true,
            mensagem: "CEP não encontrado",
            categoria: 404,
          });
        }
      } catch (error) {
        this.setNotificacao({
          status: true,
          mensagem: "Falha ao buscar CEP",
          categoria: 503,
        });
      } finally {
        this.desativarLoading();
      }
    },
  },
  watch: {},
  created() {
    if (this.tipoSubmit === "editar") {
      this.buscarRepresentante();
    }
  },
};
</script>