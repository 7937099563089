<template>
  <v-autocomplete
      label="Tipo Conta"
      placeholder="Selecione o tipo da conta"
      :items="getOptions"
      item-text="text"
      item-value="id"
      outlined
      dense
      v-model="tipo_conta_selected"
      hide-details
      clearable
      :readonly="readonly"
      prepend-inner-icon="mdi-bank"
      v-on:change="(event) => this.$emit('tipoContaSelecionado', tipo_conta_selected)"
  />
</template>
<script>
import mixinGeral from "@/mixin/geral";

export default {
  name: "AutocompleteTipoConta",
  mixins: [mixinGeral],
  props: {
    id: Number | String,
    readonly: Boolean
  },
  data: () => ({
        tipo_conta_selected: null,
        tipo_contas: [
          {id: "1", text: "Corrente"},
          {id: "2", text: "Poupança"}
        ]
      }
  ),
  computed: {
    getOptions() {
      return this.tipo_contas
    },
  },
  async created() {
    if (this.id) {
      this.tipo_conta_selected = this.id
    }
  },
  watch: {
    id: function (val) {
      this.tipo_conta_selected = val;
    }
  },
}
</script>