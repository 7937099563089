import {backend, multipart} from "./backend";
import store from "../store/index";
import helpers from "../assets/js/helpers";

const listar = async () => {
    let uri = `api/representante`;
    store.commit('SET_LOADING', true)
    let response = await backend.get(uri, helpers.montarHeaderToken());
    store.commit('SET_LOADING', false)
    return response;
};

const buscar = async (id) => {
    store.commit('SET_LOADING', true)
    let uri = `api/representante/${id}`;
    let response = await backend.get(uri, helpers.montarHeaderToken());
    store.commit('SET_LOADING', false)
    return response;
};

const cadastrar = async (form) => {
    store.commit('SET_LOADING', true)
    let uri = "api/representante";
    let response = await backend.post(uri, form, helpers.montarHeaderToken());
    store.commit('SET_LOADING', false)
    return response;
};

const editar = async (id, dados) => {
    let uri = `api/representante/${id}`;
    let response = await backend.put(uri, dados, helpers.montarHeaderToken());
    return response;
};

export default {
    listar,
    editar,
    cadastrar,
    buscar
};