import axios from 'axios';

const client = axios.create({
    baseURL: "https://viacep.com.br",
    timeout: 10000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

const buscar = async (cep) => {
    let uri = `ws/${cep}/json`;
    let response = await client.get(uri);
    return response;
};

export default {
    buscar
};