import {backend, multipart} from "./backend";
import helpers from "../assets/js/helpers";

const cadastrar = async (formulario) => {
    let uri = "api/empresas";
    const formData = traduzirFormularioEmpresa(formulario)
    let response = await multipart.post(uri, formData, helpers.montarHeaderToken());
    return response;
};

const editar = async (id, formulario) => {
    let uri = `api/empresas/${id}`;
    const formData = traduzirFormularioEmpresa(formulario)
    let response = await multipart.post(uri, formData, helpers.montarHeaderToken());
    return response;
};

const excluir = async (id) => {
    let uri = `api/empresas/${id}`;
    let response = await backend.delete(uri, helpers.montarHeaderToken());
    return response;
};

export default {
    cadastrar,
    editar,
    excluir
};

const traduzirFormularioEmpresa = formulario => {
    const formData = new FormData();
    formData.append('razao_social', formulario.razaoSocial);
    formData.append('cnpj', formulario.cnpj);
    formData.append('habilitado', formulario.habilitado);
    formData.append('cep', formulario.cep);
    formData.append('logradouro', formulario.logradouro);
    formData.append('logradouro_numero', formulario.logradouro_numero);
    formData.append('complemento', formulario.complemento);
    formData.append('bairro', formulario.bairro);
    formData.append('cidade', formulario.cidade);
    formData.append('uf', formulario.uf);
    formData.append('ddd', formulario.ddd);
    formData.append('telefone', formulario.telefone);
    formData.append('banco', formulario.banco);
    formData.append('tipo_conta', formulario.tipo_conta);
    formData.append('conta', formulario.conta);
    formData.append('agencia', formulario.agencia);
    formData.append('doc_contrato_social', formulario.doc_contrato_social);
    formData.append('doc_creci', formulario.doc_creci);
    formData.append('doc_responsavel', formulario.doc_responsavel);
    return formData;
    // return {
    //     razao_social: formulario.razaoSocial,
    //     cnpj: formulario.cnpj,
    //     habilitado: formulario.habilitado,
    //     cep: formulario.cep,
    //     logradouro: formulario.logradouro,
    //     logradouro_numero: formulario.logradouro_numero,
    //     complemento: formulario.complemento,
    //     bairro: formulario.bairro,
    //     cidade: formulario.cidade,
    //     uf: formulario.uf,
    //     ddd: formulario.ddd,
    //     telefone: formulario.telefone,
    //     banco: formulario.banco,
    //     tipo_conta: formulario.tipo_conta,
    //     conta: formulario.conta,
    //     agencia: formulario.agencia,
    //     doc_contrato_social: formulario.doc_contrato_social,
    //     doc_creci: formulario.doc_creci,
    //     doc_responsavel: formulario.doc_responsavel,
    // }
};
